import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';


export default function FormErrors(props) {
  const {
    error,
  } = props;

  const classes = useStyles(props);

  const displayedError = displayError(error) || null;
  return displayedError && (
    <Typography className={classes.root} color="error">
      {displayedError}
    </Typography>
  );
}

export function getApiError(error, flat = false) {
  if (!error || !error.response) return null;
  if (!error.response.data) {
    return flat ? error.response.statusText : [error.response.statusText];
  }
  if (Array.isArray(error.response.data)) {
    return flat ? error.response.data[0] : error.response.data;
  }
  if (typeof error.response.data.detail === 'string') {
    return flat ? error.response.data.detail : [error.response.data.detail];
  }
  if (Array.isArray(error.response.data.detail)) {
    return flat ? error.response.data.detail[0] : error.response.data.detail;
  }
  const parsed = displayError(error.response.data);
  if (typeof parsed === 'string') {
    return flat ? parsed : [parsed];
  }
  return flat ? parsed[0] : parsed;
}

export function displayError(error) {
  if (!error) return null;
  if (typeof error === 'string') return error;
  if (error.map) return error.map(displayError);
  return Object.entries(error)
    .map(
      ([key, value]) => (
        key === 'nonFieldErrors'
          ? displayError(value)
          : `${key}: ${displayError(value)}`
      ),
    );
}

FormErrors.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  newLine: PropTypes.bool,
  align: PropTypes.string,
};

FormErrors.defaultProps = {
  error: null,
  isFetching: false,
  align: 'initial',
};

const useStyles = makeStyles(() => ({
  root: ({ align }) => ({
    textAlign: align,
  }),
}));
