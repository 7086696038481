import React from 'react';

import Message from 'Common/Message';
import useMessages from 'Hooks/Messages';

export default function AppMessages(props) {
  const {
    messages,
    onClose,
  } = useAppMessages(props);

  return (
    messages.map((message, index) => (
      <Message
        {...message}
        position={index}
        onClose={onClose}
      />
    ))
  );
}

const useAppMessages = () => {
  const {
    messages,
    clear,
  } = useMessages();

  return {
    messages,
    onClose: clear,
  };
};
