import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';

import ErrorBoundary from 'Common/ErrorBoundary';
import pages from 'Pages';

export default class LazyPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      name,
    } = props;

    this.PageComponent = React.lazy(pages[name][1]);
  }

  render() {
    const {
      ...errorBoundaryProps
    } = this.props;

    const { PageComponent } = this;

    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <React.Suspense fallback={<LinearProgress />}>
          <PageComponent />
        </React.Suspense>
      </ErrorBoundary>
    );
  }
}

LazyPage.propTypes = {
  name: PropTypes.oneOf(Object.keys(pages)).isRequired,
};
