import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { __RouterContext as RouterContext } from 'react-router';

import pages from 'Pages';

export default function useRouter() {
  const { location, history, match } = React.useContext(RouterContext);
  const routes = React.useMemo(() => Object.entries(pages)
    .reduce(
      (acc, [name, [url]]) => ({
        ...acc,
        [name]: url,
      }),
      {},
    ),
  []);

  const goBack = React.useCallback(
    () => history.go(-1),
    [history],
  );
  const goForward = React.useCallback(
    () => history.go(1),
    [history],
  );
  const navigate = React.useCallback(
    (...args) => history.push(...args),
    [history],
  );
  const updateQuery = React.useCallback(
    (q, queryString = null) => {
      const searchParams = Object.entries(q)
        .reduce(
          (acc, [key, value]) => (
            value
              ? (acc.set(key, value.toString()) || acc)
              : (acc.delete(key) || acc)
          ),
          new URLSearchParams(queryString === null ? history.location.search : queryString),
        );
      const newQueryString = unescape(searchParams.toString());
      const newQuery = newQueryString ? `?${newQueryString}` : '';
      history.replace(`${history.location.pathname}${newQuery}`);
    },
    [history],
  );
  const setQuery = React.useCallback(
    q => updateQuery(q, ''),
    [updateQuery],
  );
  const query = React.useMemo(
    () => (
      Array.from(new URLSearchParams(location.search).entries()).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {},
      )
    ),
    [location.search],
  );

  return {
    reverse,
    routes,
    location,
    history,
    match,
    goForward,
    goBack,
    navigate,
    query,
    updateQuery,
    setQuery,
  };
}

function reverse(url, params, queryStringParams) {
  const query = Object.entries(queryStringParams || {})
    .reduce((result, [key, value]) => result.set(key, value) || result, new URLSearchParams())
    .toString();
  const queryString = query && `?${query}`;
  const reversedUrl = Object.entries(params || {})
    .reduce((result, [key, value]) => result.replace(`:${key}`, value), url);
  return reversedUrl + queryString;
}
