import React from 'react';

import { getApiError } from 'Common/FormError';
import { AppContext } from 'App';

const initState = {
  messages: [],
};

export default function useMessages(config) {
  const {
    size = 5,
    autoHide = true,
    hideAfter = 10000,
  } = config || {};

  const { messages: { state = initState, setState } } = React.useContext(AppContext);

  const {
    messages,
  } = state;
  const setMessages = React.useCallback(
    newMessages => setState({ messages: newMessages }),
    [setState],
  );

  const push = React.useCallback(
    message => setMessages(
      [
        ...messages,
        {
          key: new Date().getTime().toString(),
          autoHide,
          hideAfter,
          ...message,
        },
      ].slice(messages.length === size ? 1 : 0),
    ),
    [autoHide, hideAfter, messages, setMessages, size],
  );
  const clear = React.useCallback(
    () => setMessages([]),
    [setMessages],
  );
  const success = React.useCallback(
    message => push({
      message,
      type: 'success',
    }),
    [push],
  );
  const info = React.useCallback(
    message => push({
      message,
      type: 'info',
    }),
    [push],
  );
  const warning = React.useCallback(
    message => push({
      message,
      type: 'warning',
    }),
    [push],
  );
  const error = React.useCallback(
    message => push({
      message,
      type: 'error',
    }),
    [push],
  );
  const apiError = React.useCallback(
    e => push({
      message: getApiError(e, true),
      type: 'error',
    }),
    [push],
  );

  return {
    messages,
    clear,
    success,
    info,
    warning,
    error,
    apiError,
  };
}
