import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';

import Icon from 'Common/Icon';
import useDevice from 'Hooks/Device';

export default function Message(props) {
  const {
    message,
    type,
    onClose,
    snackbarAutoHideDuration,
    verticalAnchorOrigin,
  } = useMessage(props);

  const classes = useStyles(props);

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: verticalAnchorOrigin,
        horizontal: 'center',
      }}
      autoHideDuration={snackbarAutoHideDuration}
      onClose={onClose}
      className={classes.position}
    >
      <SnackbarContent
        className={classNames(classes.root)}
        message={(
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item className={classNames(classes.messageItem)}>
              <Icon
                className={classNames(classes.icon, classes[type])}
                icon={icons[type]}
              />
            </Grid>
            <Grid item className={classNames(classes.messageItem, classes.messageItemText)}>
              <Typography
                variant="body2"
                className={classes.text}
              >
                {message}
              </Typography>
            </Grid>
          </Grid>
        )}
      />
    </Snackbar>
  );
}

const useMessage = (props) => {
  const {
    message,
    type,
    autoHide,
    hideAfter,
    onClose,
  } = props;

  const { screenSize } = useDevice();

  const snackbarAutoHideDuration = autoHide ? hideAfter : undefined;
  const verticalAnchorOrigin = screenSize === 'mobile' ? 'bottom' : 'top';

  return {
    message,
    type,
    onClose,

    snackbarAutoHideDuration,
    verticalAnchorOrigin,
  };
};

const colorFactor = 400;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    maxWidth: theme.breakpoints.values.md,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'fit-content',
    },
  },
  success: {
    color: green[colorFactor],
  },
  error: {
    color: red[colorFactor],
  },
  info: {
    color: theme.palette.primary.contrastText,
  },
  warning: {
    color: amber[colorFactor],
  },
  icon: {
    fontSize: 'xx-large',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 'x-large',
    },
  },
  closeIcon: {
    color: theme.palette.primary.contrastText,
  },
  messageItem: {
    minWidth: 0,
  },
  messageItemText: {
    flex: 1,
    display: 'grid',
  },
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'fit-content',
    maxWidth: '300px',
    [theme.breakpoints.down('xs')]: {
      width: 'fit-content',
      maxWidth: `calc(100vw - ${theme.spacing(10)}px)`,
    },
  },
  position: props => ({
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(1 + 10 * props.position),
    },
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(1 + 10 * props.position),
    },
  }),
}));

const icons = {
  success: 'check-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle',
  info: 'info-circle',
};

Message.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  message: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  type: PropTypes.oneOf(Object.keys(icons)).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  autoHide: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  hideAfter: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  onClose: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  position: PropTypes.number,
};

Message.defaultProps = {
  autoHide: true,
  hideAfter: 10000,
  position: 0,
  onClose: undefined,
};
