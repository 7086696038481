import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './Services/serviceWorker';
import sentry from './Services/sentry';
import fontAwesome from './Services/fontAwesome';
import locale from './Services/locale';
import App from './App';

window.addEventListener('load', () => {
  serviceWorker.unregister();
  locale.init();
  sentry.init();
  fontAwesome.init();

  ReactDOM.render(
    <React.StrictMode><App /></React.StrictMode>,
    document.getElementById('root'),
  );
});
