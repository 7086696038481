import React from 'react';
import * as Router from 'react-router-dom';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';

import theme from 'Services/theme';
import AppMessages from 'Global/AppMessages';
import AppContent from 'Global/AppContent';

export const AppContext = React.createContext({});

export default function App() {
  const [auth, setAuth] = React.useState();
  const [messages, setMessages] = React.useState();
  const [athleteFilter, setAthleteFilter] = React.useState();

  const context = React.useMemo(
    () => ({
      messages: {
        state: messages,
        setState: setMessages,
      },
      auth: {
        state: auth,
        setState: setAuth,
      },
      athleteFilter: {
        state: athleteFilter,
        setState: setAthleteFilter,
      },
    }),
    [messages, auth, athleteFilter],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router.BrowserRouter>
        <React.Suspense fallback={<LinearProgress />}>
          <AppContext.Provider value={context}>
            <AppMessages />
            <AppContent />
          </AppContext.Provider>
        </React.Suspense>
      </Router.BrowserRouter>
    </ThemeProvider>
  );
}
