import * as Sentry from '@sentry/browser';
import config from 'config';

export default {
  init() {
    process.env.NODE_ENV !== 'development' && Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: config.version,
      blacklistUrls: [
        'http://127.0.0.1',
        'http://localhost',
      ],
    });
  },
};
