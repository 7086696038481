import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import Icon from 'Common/Icon';
import useRouter from 'Hooks/Router';


export default function Issue(props) {
  const {
    text,
    icon,
    onHomeClick,
    onReloadClick,
  } = useIssue(props);

  const classes = useStyles();

  return (
    <div className={classNames(classes.root)}>
      <Grid container justify="space-between">
        {onHomeClick && (
          <Tooltip
            title={window.django.pgettext('issue', 'Back to news')}
          >
            <IconButton
              onClick={onHomeClick}
              color="primary"
              variant="outlined"
              className={classes.iconButton}
            >
              <Icon icon="home" />
            </IconButton>
          </Tooltip>
        )}
        {onReloadClick && (
          <Tooltip
            title={window.django.pgettext('issue', 'Reload the page')}
          >
            <IconButton
              onClick={onReloadClick}
              color="primary"
              variant="outlined"
              className={classes.iconButton}
            >
              <Icon icon="sync-alt" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      {icon && <Icon className={classNames(classes.icon)} icon={icon} />}
      <Typography className={classNames(classes.text)}>
        {text}
      </Typography>
    </div>
  );
}

const useIssue = (props) => {
  const {
    message,
    icon,
    pure,
  } = props;

  const { navigate, routes } = useRouter();

  let text;
  if (!message || message === defaultMessage) {
    text = window.django.pgettext('issue', 'Unknown error');
  } else if (typeof message === 'string' || React.isValidElement(message)) {
    text = message;
  } else {
    text = message.toString();
  }

  const handleHomeClick = React.useCallback(
    () => navigate(
      routes.news,
    ),
    [navigate, routes],
  );
  const handleReloadClick = React.useCallback(
    () => window.location.reload(true),
    [],
  );

  return {
    text,
    icon,
    onHomeClick: !pure && handleHomeClick,
    onReloadClick: !pure && handleReloadClick,
  };
};

const defaultMessage = {};


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 40,
  },
  icon: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.error.main,
  },
  text: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

Issue.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  // eslint-disable-next-line react/no-unused-prop-types
  icon: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  pure: PropTypes.bool,
};

Issue.defaultProps = {
  message: defaultMessage,
  icon: 'exclamation-circle',
  pure: false,
};
