import React from 'react';

import Issue from 'Common/Issue';

export function PermissionIssue(props) {
  return (
    <Issue {...{
      message: window.django.pgettext('issue', 'You do not have permission to perform this action'),
      icon: 'ban',
      ...props,
    }}
    />
  );
}

export function NotFoundIssue(props) {
  return (
    <Issue {...{
      message: window.django.pgettext('issue', 'Not found'),
      icon: 'question-circle',
      ...props,
    }}
    />
  );
}


export function ServerErrorIssue(props) {
  return (
    <Issue {...{
      message: window.django.pgettext('issue', 'Server Error'),
      icon: 'bug',
      ...props,
    }}
    />
  );
}

export function ValidationIssue(props) {
  return (
    <Issue {...{
      message: window.django.pgettext('issue', 'Invalid data'),
      icon: 'band-aid',
      ...props,
    }}
    />
  );
}
