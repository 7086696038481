export default {
  news: [
    '/',
    () => import('Pages/News'),
  ],
  kubiosDashboard: [
    '/readiness',
    () => import('Pages/KubiosDashboard'),
  ],
  program: [
    '/program',
    () => import('Pages/Program'),
  ],
  diary: [
    '/diary',
    () => import('Pages/Diary'),
  ],
  analytics: [
    '/analytics',
    () => import('Pages/AnalyticsDashboard'),
  ],
  forms: [
    '/forms',
    () => import('Pages/News'),
  ],
  exerciseBook: [
    '/exercises',
    () => import('Pages/ExerciseBook'),
  ],
  resetPassword: [
    '/reset-password/:uid/:token',
    () => import('Pages/AuthResetPassword'),
  ],
  resetPasswordRequest: [
    '/reset-password',
    () => import('Pages/AuthResetPasswordRequest'),
  ],
  activate: [
    '/activate/:key',
    () => import('Pages/AuthActivate'),
  ],
  login: [
    '/login',
    () => import('Pages/AuthLogin'),
  ],
};
