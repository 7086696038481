import { createMuiTheme } from '@material-ui/core/styles';
import {
  blue, green, grey, orange, red,
} from '@material-ui/core/colors';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      dark: '#1a1a34',
      main: '#242751',
      light: '#2b2e66',
      contrastText: 'rgb(255, 255, 255)',
    },
    secondary: {
      dark: '#af392a',
      main: '#e9472f',
      light: '#fb482f',
      contrastText: 'rgb(255, 255, 255)',
    },
    tertiary: {
      dark: '#ca9923',
      main: '#fecd23',
      light: '#feeb15',
      contrastText: 'rgb(255, 255, 255)',
    },
    info: {
      dark: blue[100],
      main: blue[400],
      light: blue[600],
      contrastText: 'rgb(255, 255, 255)',
    },
    success: {
      dark: green[100],
      main: green[400],
      light: green[600],
      contrastText: 'rgb(255, 255, 255)',
    },
    warning: {
      dark: orange[100],
      main: orange[400],
      light: orange[600],
      contrastText: 'rgb(255, 255, 255)',
    },
    error: {
      dark: red[100],
      main: red[400],
      light: red[600],
      contrastText: 'rgb(255, 255, 255)',
    },
    neutral: {
      dark: grey[100],
      main: grey[400],
      light: grey[600],
      contrastText: 'rgb(255, 255, 255)',
    },
  },
  overrides: {
    '@global': {
      /* total width */
      '::-webkit-scrollbar': {
        background: '#fff',
        width: 16,
      },
      /* background of the scrollbar except button or resizer */
      '::-webkit-scrollbar-track': {
        background: '#fff',
      },
      /* scrollbar itself */
      '::-webkit-scrollbar-thumb': {
        background: '#babac0',
        borderRadius: 16,
        border: '4px solid #fff',
      },
      /* set button(top and bottom of the scrollbar) */
      '::-webkit-scrollbar-button': {
        display: 'none',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 24,
        height: '100%',
      },
    },
    MuiInput: {
      root: {
        paddingBottom: 4,
      },
    },
    MuiMenuItem: {
      root: {
        cursor: 'pointer',
        outline: 'none',
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected[data-isnew]': {
          background: '#5559eb',
          color: 'white',
        },
      },
    },
    MUIDataTableSearch: {
      searchText: {
        '@media (max-width: 599px)': {
          flex: 1,
        },
      },
    },
    MUIDataTableBodyRow: {
      root: {
        cursor: 'pointer',
      },
    },
  },
});
