import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Router from 'react-router';

import LinearProgress from '@material-ui/core/LinearProgress';

import pages from 'Pages';
import { PermissionIssue, NotFoundIssue } from 'Common/IssueVariations';
import LazyPage from 'Common/LazyPage';

function AppContent() {
  return (
    <Router.Switch>
      {Object.entries(pages)
        .map(([name, [path]]) => (
          <Router.Route
            key={path}
            path={path}
            exact
            render={() => <LazyPage name={name} />}
          />
        ))
      }
      <Router.Route
        path="/admin"
        exact
        component={AdminRoute}
      />
      <Router.Route
        component={FallbackRoute}
      />
    </Router.Switch>
  );
}

const AdminRoute = () => {
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      window.location = 'http://localhost:20041';
    } else {
      window.location.reload(true);
    }
  }, []);
  return (
    <LinearProgress />
  );
};

const FallbackRoute = () => {
  // check permission issue from context or something
  // eslint-disable-next-line no-constant-condition
  if (false) return <PermissionIssue />;
  return <NotFoundIssue />;
};

export default React.memo(AppContent);
