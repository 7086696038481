export default {
  set(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  },

  get(key) {
    const data = window.localStorage.getItem(key);
    return data && JSON.parse(data);
  },

  reset() {
    window.localStorage.clear();
  },
};
