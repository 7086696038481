import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Issue from 'Common/Issue';
import { PermissionIssue, NotFoundIssue, ServerErrorIssue } from 'Common/IssueVariations';


export default class ErrorBoundary extends React.Component {
  state = {
    error: null,
    isOpen: false,
  };

  sentryReportDialogOptions = {
    title: window.django.pgettext('error boundary sentry report', 'It looks like we’re having issues.'),
    subtitle: window.django.pgettext('error boundary sentry report', 'Our team has been notified.'),
    subtitle2: window.django.pgettext('error boundary sentry report', 'If you’d like to help, tell us what happened below.'),
    labelName: window.django.pgettext('error boundary sentry report', 'Name'),
    labelEmail: window.django.pgettext('error boundary sentry report', 'Email'),
    labelComments: window.django.pgettext('error boundary sentry report', 'What happened?'),
    labelClose: window.django.pgettext('error boundary sentry report', 'Close'),
    errorGeneric: window.django.pgettext('error boundary sentry report', 'An unknown error occurred while submitting your report. Please try again.'),
    errorFormEntry: window.django.pgettext('error boundary sentry report', 'Some fields were invalid. Please correct the errors and try again.'),
    successMessage: window.django.pgettext('error boundary sentry report', 'Your feedback has been sent. Thank you!'),
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handleReportClick() {
    this.setState({
      isOpen: true,
    });

    Sentry.showReportDialog(this.sentryReportDialogOptions);
  }

  render() {
    const { error, isOpen } = this.state;
    const {
      api,
      display,
      component,
      children,
      ...issueComponentProps
    } = this.props;

    if (error) {
      if (!display) return null;

      let IssueComponent = Issue;
      if (api && error.response && error.response.status) {
        const { status } = error.response;
        if (status === 403) {
          IssueComponent = PermissionIssue;
        }
        if (status === 404) {
          IssueComponent = NotFoundIssue;
        }
        if (status === 500) {
          IssueComponent = ServerErrorIssue;
        }
      }
      const issueRenderer = component || (props => (
        <Grid container justify="center" direction="column" alignItems="center">
          <IssueComponent {...props} />
          <Button
            color="primary"
            onClick={() => !isOpen && this.handleReportClick()}
          >
            <Typography>
              {window.django.pgettext('error boundary sentry report', 'Click here to report feedback')}
            </Typography>
          </Button>
        </Grid>
      ));
      return issueRenderer(issueComponentProps);
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.instanceOf(Function),
  issueComponentProps: PropTypes.shape(Issue.propTypes),
  api: PropTypes.bool,
  display: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  component: undefined,
  issueComponentProps: undefined,
  api: false,
  display: true,
};
