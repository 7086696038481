import moment from 'moment';
import 'moment/locale/hu';
import 'moment/locale/en-gb';
import ReactApex from 'react-apexcharts';
import apexEnglishLocale from 'apexcharts/dist/locales/en.json';
import Cookies from 'js-cookie';

import storage from 'Services/storage';

export default {
  getCurrentLanguage() {
    const availableLanguages = ['en', 'hu'];
    const languageCookie = Cookies.get('DJANGO_LANGUAGE');
    const defaultLanguage = availableLanguages[0];
    if (!languageCookie) Cookies.set('DJANGO_LANGUAGE', defaultLanguage);

    return (languageCookie && availableLanguages.includes(languageCookie))
      ? languageCookie
      : defaultLanguage;
  },

  async init() {
    const currentLanguage = this.getCurrentLanguage();

    ReactApex.defaultProps.options = {
      chart: {
        locales: [
          apexEnglishLocale,
          {
            name: 'hu',
            options: {
              months: [
                'Január',
                'Február',
                'Március',
                'Április',
                'Május',
                'Június',
                'Július',
                'Augusztus',
                'Szeptember',
                'Október',
                'November',
                'December',
              ],
              shortMonths: [
                'Jan',
                'Feb',
                'Már',
                'Ápr',
                'Máj',
                'Jún',
                'Júl',
                'Aug',
                'Szep',
                'Okt',
                'Nov',
                'Dec',
              ],
              days: [
                'Vasárnap',
                'Hétfp',
                'Kedd',
                'Szerda',
                'Csütörtök',
                'Péntek',
                'Szombat',
              ],
              shortDays: [
                'Vas',
                'Hét',
                'Ke',
                'Sze',
                'Csü',
                'Pén',
                'Szo',
              ],
              toolbar: {
                exportToSVG: 'SVG Letöltése',
                exportToPNG: 'PNG Letöltése',
                menu: 'Menü',
                selection: 'Kiválasztás',
                selectionZoom: 'Kiválasztás nagyítása',
                zoomIn: 'Közelítés',
                zoomOut: 'Távolítás',
                pan: 'Mozgatás',
                reset: 'Nagyítás visszaálltása',
              },
            },
          },
        ],
        defaultLocale: currentLanguage,
      },
    };
    moment.locale(currentLanguage, { week: { dow: 1 } });
    window.moment = moment;
    initDjango();
    try {
      const cachedLocale = storage.get('locale');
      if (
        cachedLocale
        && cachedLocale.language === currentLanguage
        && cachedLocale.version === Cookies.get('app_version')
      ) {
        initDjango(cachedLocale.catalog);
        return;
      }
      await fetch('/api/1/locale/', {
        credentials: 'same-origin',
      }).then(
        async (response) => {
          if (response.status === 200) {
            const { catalog } = await response.json();
            initDjango(catalog);
            storage.set('locale', {
              catalog,
              language: currentLanguage,
              version: Cookies.get('app_version'),
            });
          } else {
            throw new Error(await response.text());
          }
        },
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  },
};

function initDjango(catalog = null) {
  if (catalog === null) {
    window.django = {
      pgettext: (helpText, str) => str,
      ngettext: (singular, plural, count) => (count === 1 ? singular : plural),
      gettext: str => str,
      interpolate(fmt, obj, named) {
        if (named) {
          return fmt.replace(/%\(\w+\)s/g, match => String(obj[match.slice(2, -2)]));
        }
        return fmt.replace(/%s/g, () => String(obj.shift()));
      },
      pluralidx: count => (count === 1 ? 0 : 1),
    };
  } else {
    window.django.gettext = function gettext(msgid) {
      const value = catalog[msgid];
      if (typeof (value) === 'undefined') {
        return msgid;
      }
      return (typeof (value) === 'string') ? value : value[0];
    };

    window.django.ngettext = function ngettext(singular, plural, count) {
      const value = catalog[singular];
      if (typeof (value) == 'undefined') {
        return (count === 1) ? singular : plural;
      }
      return value.constructor === Array ? value[window.django.pluralidx(count)] : value;
    };

    window.django.pgettext = function pgettext(context, msgid) {
      let value = window.django.gettext(`${context}\x04${msgid}`);
      if (value.indexOf('\x04') !== -1) {
        value = msgid;
      }
      return value;
    };

    window.django.npgettext = function npgettext(context, singular, plural, count) {
      let value = window.django.ngettext(`${context}\x04${singular}`, `${context}\x04${plural}`, count);
      if (value.indexOf('\x04') !== -1) {
        value = window.django.ngettext(singular, plural, count);
      }
      return value;
    };
  }
}
