import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@material-ui/styles/makeStyles';

import { ReactComponent as SailIcon } from 'Assets/Svg/sail.svg';
import { ReactComponent as WhistleIcon } from 'Assets/Svg/whistle.svg';
import { ReactComponent as KubiosLogo } from 'Assets/Svg/kubios-logo.svg';


export default function Icon(props) {
  const {
    icon,
    className,
    ...restProps
  } = props;
  if (!icon) {
    throw new Error('DEVELOPER ERROR: invalid call of Icon component without icon prop.');
  }
  const classes = useStyles();

  if (icon in SVGIcons) {
    const SVGIcon = SVGIcons[icon];
    return (
      <SVGIcon
        className={classNames(classes.svg, className)}
        {...restProps}
      />
    );
  }

  return (
    <FontAwesomeIcon
      className={classNames(classes.root, className)}
      icon={icon}
      {...restProps}
    />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '0.9em !important',
    height: '0.9em !important',
  },
  svg: {
    minWidth: '0.9em !important',
    maxWidth: '1.1em !important',
    height: '0.9em !important',
  },
}));

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: null,
};

const SVGIcons = {
  sail: SailIcon,
  whistle: WhistleIcon,
  'kubios-logo': KubiosLogo,
};
