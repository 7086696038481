// eslint-disable-next-line no-unused-vars
import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

// NOTE this must not be a useTheme because the initial values are invalid
import theme from 'Services/theme';

export default function useDevice(config) {
  const {
    desktop = 'lg',
    mobile = 'xs',
  } = config || {};

  const isDesktopMode = useMediaQuery(
    theme.breakpoints.up(desktop), { noSsr: true },
  );
  const isMobileMode = useMediaQuery(
    theme.breakpoints.down(mobile), { noSsr: true },
  );

  let screenSize = 'tablet';
  if (isDesktopMode) {
    screenSize = 'desktop';
  } else if (isMobileMode) {
    screenSize = 'mobile';
  }

  return {
    screenSize,
  };
}
